.body-1 {
  .f4;
  .font-normal;
  .lh-1;
}

.body-2 {
  .f4;
  .font-normal;
  .lh-2;
  .muli;
}

.body-3 {
  .f5;
  .font-light;
  .lh-3;
  .muli;
}
