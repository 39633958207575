.headerIcon {
  height: 25px;
  max-width: 25px;
}

.headerIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
