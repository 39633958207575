.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}

@media screen and (max-width: @breakpoint-md) {
  .flex-col-md {
    .flex-col;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .flex-col-xs {
    .flex-col;
  }
  .flex-row-xs {
    .flex-row;
  }
}
