//Custom CSS transitions
.main-nav  {
  .flex;
  .flex-col;
  .justify-between;
  justify-content: space-between;
  .navlink {
    svg {
      * {
        .transition-1;
      }
    }
  }
  .nav-item.activeLink {
    .bg-grey-light;
    .br-w-0;
    .font-bold;
  }
  .navLogo {
    .flex;
    .items-center;
    .justify-center;
    .px-10;
    .pb-5;
    .flex-row;

    img {
      max-width: 135px;
    }
  }
}
.product-type-menu {
  .px-8;
}

.nav-item {
  .unread-badge {
    .absolute;
    top: 0.08em;
    left: 1.1em;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .product-type-menu {
    .-mt-5 !important;
    .-ml-5;
    .-mr-5;
    .px-5;
    .py-2;
    .bg-white;
  }
  .main-nav  {
    box-shadow: 0 0 5px 0 @grey-dark;
  }

  .nav-item {
    border: 0px !important;
    background: @white !important;
    .text-align-center;

    .unread-badge {
      left: 51%;
    }
  }
}
