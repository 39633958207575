.likes {
  cursor: pointer;
}
.likes .goUp {
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: 0.1s ease-in-out;
}
.likes .waitDown {
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}
.likes .goDown {
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  transition: 0.1s ease-in-out;
}
.likes .waitUp {
  display: inline-flex;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}
.likes .initial {
  display: inline-flex;
  opacity: 1;
  transform: translate3d(0, 0px, 0);
  transition: 0.1s ease-in-out;
}

@particles-animation-duration: 0.8s;
@icon-animation-duration: 0.48s;
@icon-color: @red;
@icon-border-color: hsl(0, 0%, 10%);

@keyframes favorite-icon-animation {
  0% {
    opacity: 1;
    transform: scale(0.1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  80% {
    opacity: 1;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes favorite-particles-animation {
  0% {
    background-position: left center;
  }
  100% {
    background-position: right center;
  }
}

// Particles animation.
.favorite-particles-transition-enter-active {
  background-size: 2500% auto;
  background-position: left center;
  background-repeat: no-repeat;

  animation-duration: @particles-animation-duration;
  animation-timing-function: steps(24);
  animation-name: favorite-particles-animation;
}

.toggle-favorite {
  font-size: 20px;
  position: relative;

  &__icon {
    height: 1em;
    width: 1em;

    // Transition mainly for when un-favoriting
    transition: fill-opacity 0.2s, stroke 0.2s;
    fill: @icon-color;
    fill-opacity: 1;
    // stroke: @icon-border-color;

    &--favorited {
      fill-opacity: 1;
      path {
        fill: @icon-color;
      }
    }

    // Icon animation
    &--animate {
      opacity: 0;
      transform: scale(0);
      animation-duration: @icon-animation-duration;
      animation-delay: @particles-animation-duration - @icon-animation-duration;
      animation-name: favorite-icon-animation;
    }
  }

  &__particles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3em;
    height: 3em;
  }
}
.toggle-favorite {
  @media screen and (max-width: @breakpoint-xs) {
    font-size: 18px;
  }
}
