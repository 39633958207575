.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

@media screen and (max-width: @breakpoint-xs) {
  .order-1-xs {
    .order-1;
  }
  .order-2-xs {
    .order-2;
  }
  .order-3-xs {
    .order-3;
  }
}
