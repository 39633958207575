.validation-btn {
  .btn;
  .muli;

  @media screen and (max-width: @breakpoint-md) {
    .px-6;
  }

  @media screen and (max-width: @breakpoint-xs) {
    .px-4;
    .py-2;
    .f4;
  }
}
