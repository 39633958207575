.overlay {
  .relative;
  &::before {
    content: '';
    .absolute;
    .t-0;
    .l-0;
    .w-full;
    .h-full;
    .z-index-4;

    background-color: rgba(0, 0, 0, 0.4);
  }
}

.overlay-item {
  .display-none;

  &.is-visible {
    .block;
    animation: overlay-item-appear 0.2s ease forwards;
  }
}

@keyframes overlay-item-appear {
  0% {
    opacity: 0.8;
    transform: translate3d(0, 15px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
