.settings-li {
  .py-4;
  .px-3;
  .bw-0;
  .bb-w-1;
  .bs-solid;
  .bc-grey-dark;
  .flex;
  .items-center;
  .cursor-pointer;
  .transition-1;

  &:hover {
    .bg-white;
  }
}

.settings-span {
  .f4;
  .text-purple;
  .flex-grow-1;
}
