//@import "https://use.typekit.net/aoo6xbu.css";
@import url('https://fonts.googleapis.com/css2?family=Muli:wght@300;400;600;700&family=Roboto+Slab:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;600;700&display=swap');

@light: 300;
@normal: 400;
@medium: 600;
@bold: 700;

@basefont: 1;
@font-size: @basefont + 0em;
@font-family: 'Muli', sans-serif;

html {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-size: @font-size;
  font-family: @font-family;
  font-weight: @normal;
  color: @purple-dark;
}

.roboto {
  font-family: 'Roboto Slab', serif;
}

.muli {
  font-family: 'Muli', sans-serif;
}

//.sofia {
//  font-family: "sofia-pro", sans-serif;
//}

@media screen and (max-width: @breakpoint-md) {
  .muli-md {
    .muli;
  }

  .roboto-md {
    .roboto;
  }

  .muli-md {
    .muli;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .muli-xs {
    .muli;
  }

  .roboto-xs {
    .roboto;
  }
}
