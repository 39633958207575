.font-light {
  font-weight: @light;
}

.font-normal {
  font-weight: @normal;
}

.font-bold {
  font-weight: @bold;
}
