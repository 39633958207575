.opacity-generator(10);

.opacity-generator(@counter) when (@counter >= 0) {
  .opacity-generator((@counter - 1));
  .opacity-0@{counter} {
    opacity: @counter*0.1;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}
