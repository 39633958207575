.user-select-none {
  user-select: none;
}

.react-autosuggest__container {
  width: 100%;
  flex-grow: 1;
}

.react-autosuggest__input {
  .appearance-0;
  .bw-0;
  .text-purple;
  .f4;
  .muli;
  .flex-grow-1;
  .py-3;
  .lh-3;
  .outline-0;
  width: 100%;
}

.react-autosuggest__suggestions-container {
  .inline-block;
  .bg-blue-light;
  .br-4;
  .column-span-2;
  z-index: 10;
}

.react-autosuggest__suggestions-container--open {
  .pb-2;
}

// Used to highlight autocomplete results
.react-autosuggest__suggestions-container em {
  font-style: normal;
  .px-1;
  .font-bold;
  .bg-blue-light;
}
