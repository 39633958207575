.btn-multiple {
  .f4;
  .font-bold;
  .lh-button;
  .muli;

  .py-3;
  .px-12;
  .text-white;

  .appearance-0;
  .bw-1;
  .bc-transparent;
  .br-5;

  .cursor-pointer;

  .transition-1;

  &.disabled {
    .bg-purple-grey;
    .pointer-0;
  }
}
