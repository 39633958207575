.box-shadow-1 {
  box-shadow: 0 1px 5px 2px #f0f0f0;
}

.box-shadow-2 {
  box-shadow: 0 2px 8px 2px #f0f0f0;
}

.box-shadow-3 {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}

.box-shadow-4 {
  box-shadow: 0 2px 4px 1px #f0f0f0;
}

.box-shadow-5  {
  box-shadow: 0 0 4px 0 rgba(154, 152, 152, 0.33);
}

.box-shadow-6  {
  box-shadow: 0 2px 8px 0 rgba(235, 230, 230, 0.56);
}

.box-shadow-7 {
  box-shadow: 0 1px 4px 1px #f0f0f0;
}

.box-shadow-8 {
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.35);
}

.hover\:box-shadow-2:hover {
  .box-shadow-2;
}

.hover\:box-shadow-4:hover {
  .box-shadow-4;
}
