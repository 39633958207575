/* ==================================
=            Style.less            =

Unique entry point for our CSS.
Includes all other pages and styles
================================== */

/* All components */
@import 'components/button/btn.less';
@import 'components/button/heart-animation.less';
@import 'components/button/btn-animation.less';
@import 'components/button/btn-secondary.less';
@import 'components/button/btn-purple.less';
@import 'components/button/btn-multiple.less';
@import 'components/button/settings.less';

@import 'components/nav/nav-links.less';

@import 'components/gallery/image-gallery.less';

@import 'components/form/input.less';
@import 'components/form/toggle.less';
@import 'components/form/radio.less';

@import 'components/route/route.less';

@import 'components/responsive/responsive.less';

@import 'components/modal/modal.less';
@import 'components/modal/onboarding.less';

@import 'components/overlay/overlay.less';

@import 'components/header/header.less';
@import 'components/profile/profile.less';
@import 'components/toastify/toastify.less';

@import 'components/im/im.less';
@import 'components/settings/panel.less';
@import 'components/settings/border.less';

@import 'components/rooms/settings.less';

@import 'components/message/message.less';
@import 'components/welcome/welcome.less';
@import 'components/poll/poll.less';

//@import 'components/search/autosuggest.less';

/* External css libraries */
@import 'functionnal/vendors/block-ui.less';
@import 'functionnal/vendors/for.less';
@import 'functionnal/vendors/normalize.less';
@import 'functionnal/vendors/toastify.less';
@import 'functionnal/vendors/react-form-input.less';

/* Responsive */
@import 'functionnal/responsive/breakpoints.less';

/* Colors */
@import 'functionnal/colors/colors.less';

/* Pages */
@import 'functionnal/pages/pages.less';

/* Layout */
@import 'functionnal/layout/grid-container.less';
@import 'functionnal/layout/display.less';
@import 'functionnal/layout/overflow.less';
@import 'functionnal/layout/position.less';
@import 'functionnal/layout/z-index.less';
@import 'functionnal/layout/grid.less';
@import 'functionnal/layout/main-layout.less';

/* Miscellaneous */
@import 'functionnal/miscellaneous/transition.less';
@import 'functionnal/miscellaneous/rounded.less';
@import 'functionnal/miscellaneous/appearance.less';
@import 'functionnal/miscellaneous/box-shadow.less';
@import 'functionnal/miscellaneous/border.less';
@import 'functionnal/miscellaneous/cursor.less';
@import 'functionnal/miscellaneous/opacity.less';
@import 'functionnal/miscellaneous/user-select.less';
@import 'functionnal/miscellaneous/outline.less';
@import 'functionnal/miscellaneous/resize.less';

/* background */
@import 'functionnal/background/background-color.less';

/* Sizing */
@import 'functionnal/sizing/width-height.less';

/* Transform */
@import 'functionnal/transform/transform-center.less';
@import 'functionnal/transform/transform-scale.less';

/* Spacing */
@import 'functionnal/spacing/margin-padding.less';

/* Flexbox */
@import 'functionnal/flexbox/display.less';
@import 'functionnal/flexbox/align-content.less';
@import 'functionnal/flexbox/align-item.less';
@import 'functionnal/flexbox/flex-direction.less';
@import 'functionnal/flexbox/flex-wrapping.less';
@import 'functionnal/flexbox/flex-grow.less';
@import 'functionnal/flexbox/flex-shrink.less';
@import 'functionnal/flexbox/justify-content.less';
@import 'functionnal/flexbox/flex.less';
@import 'functionnal/flexbox/order.less';

/* Typograhy */
@import 'functionnal/typography/body.less';
@import 'functionnal/typography/color.less';
@import 'functionnal/typography/typography.less';
@import 'functionnal/typography/font-size.less';
@import 'functionnal/typography/font-weight.less';
@import 'functionnal/typography/font-style.less';
@import 'functionnal/typography/line-height.less';
@import 'functionnal/typography/vertical-alignment.less';
@import 'functionnal/typography/text-alignment.less';
@import 'functionnal/typography/text-transform.less';

#app {
  .flex;
  .w-100-vw;
  .h-100-vh;
  height: -webkit-fill-available;
}

// Override block ui
.loading-bullet {
  color: @orange-dark !important;
}

// used in search results
.highlights {
  em {
    .font-bold;
    .yellow-tag;
  }
}

.bullets-list {
  list-style-type: disc;
  list-style-position: outside;
  margin: 20px;
}
