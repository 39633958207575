.modal-wrapper {
  .fixed;
  .pin-t;
  .pin-l;

  .w-100-vw;
  .h-100-vh;

  .z-index-9;

  background-color: rgba(0, 0, 0, 0.5);

  .flex;
  .items-center;
  .justify-center;

  .modal-overlay {
    .absolute;
    .w-full;
    .h-full;

    .pin-t;
    .pin-l;

    .z-index-1;
  }

  .modal-content {
    .relative;
    .bg-grey-light;
    .z-index-2;

    .br-8;
    .overflow-scroll;
    .box-shadow-6;

    animation: modal-appear 0.2s ease-out forwards;

    .modal-header {
      .px-10;
      .py-7;
      .text-align-left;
    }

    .modal-header-market {
      .t-0;
      .bg-grey-light;
      .z-index-8;
    }
    .modal-body  {
      .px-10;
      .py-10;
    }

    .modal-body-market {
      .pt-0;
      .min-width-50-vw;
      .max-height-66vh;
      .overflow-y-auto;
    }

    .modal-footer {
      .b-0;
      .pt-3;
      .pb-5;
      .text-align-center;
    }
    @media screen and (max-width: @breakpoint-lg) {
      .modal-body-market {
        .min-width-70-vw;
      }
    }

    @media screen and (max-width: @breakpoint-xs) {
      .w-100-vw;
      .h-100-vh;
      .br-0;

      .modal-header-market {
        .absolute;
        .w-full;
      }

      .modal-header,
      .modal-body  {
        .px-5;
        .py-6;
      }
      .modal-body  {
        .pt-28;
        .pb-24;
      }

      .modal-body-welcome {
        .pt-10;
      }

      .modal-body-market {
        .max-height-0;
        .height-100;
      }
    }
  }

  @media screen and (max-width: @breakpoint-xs) {
    .not-fullscreen-xs {
      width: auto;
      height: auto;
    }
  }
}

.modal-arrow {
  .absolute;
  .r-5;
  .t-7;

  .w-4;
  .h-4;

  .cursor-pointer;
  .transition-1;

  &:before,
  &:after {
    content: '';
    .block;

    .w-full;
    .h-2px;

    .bg-purple;
    .br-10;

    transform: translate3d(3px, 9px, 0) rotate(45deg);
  }

  &:after {
    transform: translate3d(3px, 7px, 0) rotate(-45deg);
  }

  &:hover {
    transform: scale(0.8);
    .opacity-08;
  }
}

.modal-title {
  .f2;
  .roboto;
  .text-purple-dark;
  .font-bold;
}

.modal-subtitle {
  .f3;
  .font-bold;
  .text-purple;
  .inline-block;
  .align-middle;
}

@keyframes modal-appear {
  0% {
    .opacity-05;
    transform: scale(0.9);
  }
  100% {
    .opacity-1;
    transform: scale(1);
  }
}
