.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

@media screen and (max-width: @breakpoint-xs) {
  .flex-grow-1-xs {
    .flex-grow-1;
  }
}
