.common-input {
  &::placeholder {
    .f4;
    .text-grey-lighter;
    .font-normal;
    .muli;
  }
}

.apparance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

select {
  .br-5;
  .apparance-none;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 100% 50% #fff;
}

.placeholder-text-grey-lighter {
  &::placeholder {
    .text-grey-lighter;
  }
}

.label {
  .f5;
  .mb-3;
  .text-purple-grey;
}

.form-control {
  .mb-4;
  .flex;
  .flex-col;
  label {
    .label;
  }
  input,
  textarea {
    .bg-white;
    .common-input;
    .text-purple-grey;
    .f4;
    .bw-1;
    .bc-grey-dark;
    .h-10;
    .br-5;
    .bs-solid;
    .px-3;
    .py-3;

    &.with-error {
      .bc-red;
    }
  }

  textarea {
    .h-24;
  }

  .input-helper {
    .mt-2;
  }

  select {
    .p-3;
    .bc-grey;
    .f4;
    .bg-white;
    .pr-6;
  }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px;
  }
}
