.flex  {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

@media screen and (max-width: @breakpoint-xs) {
  .flex-xs {
    .flex;
  }
}

@media screen and (max-width: @breakpoint-md) {
  .flex-none-md {
    .display-none;
  }

  .flex-md {
    .flex;
  }
}

@media screen and (max-width: @breakpoint-lg) {
  .flex-none-lg {
    .display-none;
  }

  .flex-lg {
    .flex;
  }
}
