.custom-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

.custom-gallery .image-gallery-thumbnails-container .image-gallery-thumbnail {
  .cursor-pointer;
}

.custom-gallery .image-gallery-image {
  max-height: 30vh !important;
}

.custom-gallery-display-none .image-gallery-image {
  display: none;
}

.image-gallery-thumbnail.active {
  // border: none !important;
  .bc-purple !important;
  .bw-1 !important;
}

.image-gallery-thumbnail {
  .bw-2 !important;
  &:hover {
    .bw-1 !important;
    .bc-purple !important;
  }
}
