.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-auto {
  cursor: auto;
}

.pointer-0 {
  pointer-events: none;
}
