.uppercase {
  text-transform: uppercase;
}

.yellow-tag {
  display: inline;
  position: relative;
  background: linear-gradient(178deg, rgba(255, 255, 255, 0) 50%, @yellow-light 40%);

  &:hover {
    background: linear-gradient(178deg, rgba(255, 255, 255, 0) 50%, @orange-light 80%);
  }
}
