// 24px
.f1 {
  font-size: 1.5em;
}

// 18px
.f2 {
  font-size: 1.125em;
}

// 16px
.f3 {
  font-size: 1em;
}

// 15px
.f3-5 {
  font-size: 0.9375em;
}

// 14px
.f4 {
  font-size: 0.875em;
}

// 12px
.f5 {
  font-size: 0.75em;
}

//11px
.f5-5 {
  font-size: 0.7em;
}

//10px
.f6 {
  font-size: 0.625em;
}

@media screen and (max-width: @breakpoint-md) {
  .f2-md {
    .f2;
  }
  .f3-md {
    .f3;
  }
  .f4-md {
    .f4;
  }
  .f5-md {
    .f5;
  }
  .f6-md {
    .f6;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .f2-xs {
    .f2;
  }
  .f3-xs {
    .f3;
  }
  .f4-xs {
    .f4;
  }
  .f5-xs {
    .f5;
  }
  .f6-xs {
    .f6;
  }
}
