.app-wrapper {
  display: flex;
  nav {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    width: 20vw;
  }
  .content-wrapper {
    width: 80vw;
    margin-left: 20vw;
  }
}

@media screen and (max-width: @breakpoint-md) {
  .app-wrapper {
    display: block;
    nav {
      width: 30vw;
    }
    .content-wrapper {
      width: 70vw;
      margin-left: 30vw;
    }
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .app-wrapper {
    display: block;
    nav {
      width: 100vw;
      position: fixed;
      bottom: 0;
      top: unset;
    }
    .content-wrapper {
      width: 100vw;
      margin-left: 0;
    }
  }
}
