.btn {
  &.secondary {
    .bw-1;
    .bc-orange;
    .bg-transparent;
    .text-orange;

    &:hover {
      &:not(.disabled) {
        .bg-white;
        .bc-orange-dark;
        .text-orange-dark;
      }
    }

    &.disabled {
      .opacity-06;
      .bg-transparent;
    }
  }
}
