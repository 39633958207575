.btn {
  .f4;
  .font-normal;
  .bg-orange;
  .lh-button;
  .muli;

  .py-3;
  .px-12;
  .text-white;

  .appearance-0;
  .bw-1;
  .bc-transparent;
  .br-5;

  .cursor-pointer;

  .transition-1;

  &:hover {
    &:not(.disabled) {
      .bg-orange-dark;
    }
  }

  &.disabled {
    .bg-orange-light;
    .pointer-0;
  }
}
