.unread-badge {
  width: 10px;
  height: 10px;
  top: 14px;
  .r-0;
  .absolute;
  .mr-3;
  .br-50;
  .bg-red;
  .bs-solid;
  .bw-1;
  .bc-red-light;
  animation: change-unread-badge-background 3s linear infinite;
}

@keyframes change-unread-badge-background {
  0% {
    background-color: @red;
    .bc-red-light;
  }
  50% {
    background: @red-lighter;
    .bc-red;
  }
  100% {
    background: @red;
    .bc-red-light;
  }
}

.groupchat-item-unread-box,
.chat-item-unread-box {
  box-shadow: -2px 3px 4px 1px @grey-dark;
}

.filter-badge {
  width: 12px;
  height: 12px;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  .absolute;
  .br-50;
  .bg-purple;
  .bs-solid;
  .bw-1;
  .bc-purple;
  color: white;
  .roboto;
  .f6;
  .font-bold;
  .text-align-center;
}
