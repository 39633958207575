* {
  box-sizing: border-box;
}

body {
  background-color: @grey-light;
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
