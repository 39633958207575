.route-wrapper {
  animation: route-enter 0.25s cubic-bezier(0.18, -0.38, 0.28, 1.34) forwards;
}

.grid-main {
  animation: main-enter 0.25s ease forwards;
}

@keyframes route-enter {
  0% {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  100%  {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes main-enter {
  0% {
    opacity: 0;
  }
  100%  {
    opacity: 1;
  }
}
