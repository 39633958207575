.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

@media screen and (max-width: @breakpoint-md) {
  .text-align-center-md {
    .text-align-center;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .text-align-right-xs {
    .text-align-right;
  }

  .text-align-center-xs {
    .text-align-center;
  }

  .text-align-left-xs {
    .text-align-left;
  }
}
