.toast {
  border-radius: 5px !important;
  width: 100% !important;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}

.Toastify__toast-container {
  font-size: 0.9em;
  width: 30% !important;
}
.Toastify__toast-body {
  padding: 8px !important;
}

@media screen and (max-width: @breakpoint-xs) {
  .Toastify__toast-container {
    width: 100% !important;
  }
}
