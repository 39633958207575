.pollItem {
  display: flex;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  height: 32px;
  margin-bottom: 12px;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pollItemResult {
  display: flex;
  width: 100%;
  border: 1px solid;
  border-radius: 5px;
  height: 32px;
  align-items: center;
  padding-left: 8px;
  cursor: pointer;
  margin-bottom: 12px;
  .bc-orange-dark;
  .text-orange-dark;
}

.valuePollItem {
  margin-left: 40%;
  font-weight: bold;
  font-style: italic;
}
