main {
  &.is-fullscreen {
    .z-index-9;
    .p-0;
    .w-full;
    .absolute;
    .t-0;
    .overflow-hidden;
    .l-0;
    /* it's a test */
    min-height: -moz-available;
    height: -moz-available;

    // Second test but real
    min-height: -webkit-fill-available;
    height: -webkit-fill-available;

    .full-width-height-component  {
      // .absolute;
      .pin-t;
      .pin-l;
      .pin-r;
      .h-full;
    }
  }
}

@media screen and (max-width: @breakpoint-xs) {
  main {
    &.is-fullscreen {
      .full-width-height-component  {
        width: 100vw;
      }
    }
  }
}
