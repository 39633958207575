.lh-button {
  line-height: 1.07;
}

.lh-1 {
  line-height: 1.43em;
}

.lh-2 {
  line-height: 1.36em;
}

.lh-3 {
  line-height: 1.25em;
}
