.border-generator(64);

.border-generator(@counter) when (@counter >= 0) {
  .border-generator((@counter - 1));
  .br-@{counter} {
    border-radius: @counter*1px;
  }
}

.br-bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.br-top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.br-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.br-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.br-tl-0 {
  border-top-left-radius: 0;
}

.br-tr-0 {
  border-top-right-radius: 0;
}
