.panel {
  .w-40;
  .flex;
  .flex-col;
}

@media screen and (max-width: @breakpoint-xs) {
  .panel {
    .w-full;
  }
}
