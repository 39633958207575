.date-breakpoint {
  .f5;
  .font-bold;
  .br-12;
  .px-4;
  .h-5;
  .flex;
  .align-center;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #fef3cd, #fef3cd);
  color: @orange-dark;
}

.new-messages-breakpoint {
  .f5;
  .font-bold;
  .br-12;
  .px-4;
  .h-5;
  .flex;
  .align-center;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, @purple-lighter, @purple-lighter);
  color: @purple-dark;
}

.message-reply {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
  margin-left: 8px;
  padding: 5px 10px;
  background-color: #fafafa;
  border-left-width: 4px;
  border-left-color: #271e42;
  border-left-style: solid;

  .message-reply-txt {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .message-reply-assets {
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .message-reply-img {
      max-width: 200px;
    }

    .message-reply-close {
      background-color: transparent;
      border-width: 1px;
      border-radius: 5px;
      padding: 2px 6px;
      position: absolute;
      right: 14px;
    }
  }
}


.message-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #fafafa;
  border-left-width: 4px;
  border-left-color: #271e42;
  border-left-style: solid;
}
