.-z-index {
  z-index: -1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 20;
}

.z-index-3 {
  z-index: 30;
}

.z-index-4 {
  z-index: 40;
}

.z-index-5 {
  z-index: 50;
}

.z-index-6 {
  z-index: 60;
}

.z-index-7 {
  z-index: 70;
}

.z-index-8 {
  z-index: 80;
}

.z-index-9 {
  z-index: 1000;
}

.z-index-10 {
  z-index: 1010;
}

.z-index-11 {
  z-index: 1011;
}
