.sizing-generator(40);

.sizing-generator(@counter) when (@counter >= 0) {
  .sizing-generator((@counter - 1));

  .w-@{counter} {
    width: @counter*0.25rem;
  }

  .h-@{counter} {
    height: @counter*0.25rem;
  }

  @media screen and (max-width: @breakpoint-md) {
    .w-@{counter}-md {
      width: @counter*0.25rem;
    }
    .h-@{counter}-md {
      height: @counter*0.25rem;
    }
  }

  @media screen and (max-width: @breakpoint-xs) {
    .w-@{counter}-xs {
      width: @counter*0.25rem !important;
    }
    .h-@{counter}-xs {
      height: @counter*0.25rem !important;
    }
  }
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-85 {
  width: 85%;
}

.w-15 {
  width: 15%;
}

.w-half {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-46 {
  width: 46.5%;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 25%;
}

.w-85 {
  width: 85%;
}

.w-60 {
  width: 60%;
}

.w-49 {
  width: 49%;
}

.w-40 {
  width: 40%;
}

.h-1px {
  height: 1px;
}

.h-2px {
  height: 2px;
}

.h-3px {
  height: 3px;
}

.h-19px {
  height: 19px;
}

.h-full {
  height: 100%;
}

.w-80-vw {
  width: 80vw;
}

.w-100-vw {
  width: 100vw;
}

.h-100-vh {
  height: 100vh;
}

// min height & width
.max-width-75-percent {
  max-width: 75%;
}

.max-width-60-vw {
  max-width: 60vw;
}

.max-width-100-percent {
  max-width: 100%;
}

.max-width-90-percent {
  max-width: 90%;
}

.height-100vh-md .max-height-100-percent {
  max-height: 100%;
}

.min-height-100-vh {
  min-height: 100vh;
}

.min-height-66-vh {
  min-height: 66vh;
}

.min-height-89-vh {
  min-height: 89vh;
}

.min-height-94-vh {
  min-height: 94vh;
}

.max-width-320 {
  max-width: 320px;
}

.max-height-66vh {
  max-height: 66vh;
}

.height-66vh {
  height: 66vh;
}

.height-60vh {
  height: 60vh;
}

.max-height-40vh {
  max-height: 40vh;
}

.max-height-75vh {
  max-height: 75vh;
}

.min-width-50-vw {
  min-width: 50vw;
}

.height-100 {
  height: 100vh;
}

.height-75 {
  height: 75vh;
}

.height-0 {
  height: 0;
}

.w-fit {
  width: fit-content;
}

.max-height-0 {
  max-height: none;
}

.min-width-70-vw {
  min-width: 70vw;
}

.min-width-120 {
  min-width: 120px;
}

.min-width-30 {
  min-width: 30%;
}

@media screen and (max-width: @breakpoint-lg) {
  .min-width-70-vw-lg {
    min-width: 70vw;
  }
}

@media screen and (max-width: @breakpoint-md) {
  .max-height-0-md {
    max-height: none;
  }

  .max-width-0-md {
    max-width: none;
  }

  .min-height-100vh-md {
    min-height: 100vh;
  }

  .height-100vh-md {
    height: 100vh;
  }

  .w-100-md {
    width: 100%;
  }

  .w-49-md {
    .w-49;
  }

  .w-75-md {
    .w-75;
  }
}

@media screen and (max-width: @breakpoint-xs) {
  .w-auto-xs {
    width: auto;
  }

  .h-auto-xs {
    height: auto;
  }

  .w-100-xs {
    width: 100%;
  }

  .w-half-xs {
    width: 50%;
  }

  .height-100vh-xs {
    height: 100vh;
  }

  .height-100-xs {
    height: 100%;
  }

  .height-50-xs {
    height: 50%;
  }

  .max-height-0-xs {
    max-height: none;
  }

  .max-width-0-xs {
    max-width: none;
  }

  .max-width-80-xs {
    max-width: 80%;
  }
}
