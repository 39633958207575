.items-stretch {
  align-items: stretch;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}

.align-self-flex-end {
  align-self: flex-end;
}
