.userTag {
  background: @orange-light;
}
.profileAvatar {
  height: 92;
  width: 92;
  background-size: cover;
  background-position: center;
  .br-50;
  .text-align-center;

  .letter {
    padding-top: 10;
    font-size: 60;
  }
}

.userIcon,
.smallProfileAvatar {
  height: 36;
  width: 36;

  .letter {
    padding-top: 8;
    font-size: 18;
  }
}

.userIconSmall {
  height: 21;
  width: 21;

  .letter {
    padding-top: 5;
    font-size: 11;
  }
}
