.modal-welcome-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-welcome-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-welcome-description {
  .f4;
  .text-purple-grey;
  .text-align-center;
}

.modal-welcome-title {
  .f3;
  .roboto;
  .text-purple-dark;
  .font-bold;
  .text-align-center;
}

.modal-welcome-info {
  .text-purple-grey;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 20vh;
}

.step-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 16px;
  border: solid 1px #ff9000;
}
.step-indicator-full {
  background-color: #ff951c;
}

.step-indicator-container {
  display: flex;
  margin-bottom: 16px;
}
.step-indicator:first-of-type {
  margin-left: 0px;
}

.button-container {
  width: 100%;
  justify-content: center;
  display: flex;
}

.image-welcome-modal {
  height: 30vh;
  width: auto;
  object-fit: contain;
}

@media screen and (max-width: @breakpoint-xs) {
  .modal-welcome-footer {
    position: relative;
    margin-top: 50px;
  }
}
